<template>
  <div class="history-table__row">
    <div class="history-table__text text-small">
      <template v-if="suborder.contract_name">
        {{ suborder.contract_name }} <template v-if="suborder.contract_date">
        от {{ suborder.contract_date }}
      </template>
      </template>
    </div>
    <div v-if="suborder.is_expired" class="history-table__status">
      <svg class="s-icon s-icon--md">
        <use xlink:href="/img/svg/sprite1.svg#warning" />
      </svg>
      <span class="history-table__status-text">Просрочен</span>
    </div>
    <template v-else-if="paymentStatusTitle || statusTitle">
      <div v-if="paymentStatusTitle" class="history-table__status">
        <svg v-if="paymentStatusIcon" class="s-icon s-icon--md">
          <use :xlink:href="`/img/svg/sprite1.svg#${paymentStatusIcon}`" />
        </svg>
        <span v-if="paymentStatusTitle" class="history-table__status-text">{{ paymentStatusTitle }}</span>
      </div>
      <div v-else class="history-table__status">
        <svg v-if="statusIcon" class="s-icon s-icon--md">
          <use :xlink:href="`/img/svg/sprite1.svg#${statusIcon}`" />
        </svg>
        <span v-if="statusTitle" class="history-table__status-text">{{ statusTitle }}</span>
      </div>
    </template>
    <div v-else class="history-table__status">
      <svg v-if="prepaymentStatusIcon" class="s-icon s-icon--md">
        <use :xlink:href="`/img/svg/sprite1.svg#${prepaymentStatusIcon}`" />
      </svg>
      <span v-if="prepaymentStatusTitle" class="history-table__status-text">{{ prepaymentStatusTitle }}</span>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { getStatusTitle, getStatusIcon } from '@/common/suborderStatus'
import { getStatusTitle as getPaymentStatusTitle, getStatusIcon as getPaymentStatusIcon } from '@/common/suborderPaymentStatus'
import { getStatusTitle as getPrepaymentStatusTitle, getStatusIcon as getPrepaymentStatusIcon } from '@/common/prepaymentStatus'

export default {
  props: {
    suborder: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const statusTitle = computed(() => getStatusTitle(props.suborder.status))
    const statusIcon = computed(() => getStatusIcon(props.suborder.status))
    const paymentStatusTitle = computed(() => getPaymentStatusTitle(props.suborder.payment_status))
    const paymentStatusIcon = computed(() => getPaymentStatusIcon(props.suborder.payment_status))
    const prepaymentStatusTitle = computed(() => getPrepaymentStatusTitle(props.suborder.prepayment_status))
    const prepaymentStatusIcon = computed(() => getPrepaymentStatusIcon(props.suborder.prepayment_status))
    return {
      statusTitle,
      statusIcon,
      paymentStatusTitle,
      paymentStatusIcon,
      prepaymentStatusTitle,
      prepaymentStatusIcon
    }
  }
}
</script>

